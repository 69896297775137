<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight bbox plr13 pt60 pb30 positionr">
      <div v-if="machineTypeList.length == 0"
           class="kong positiona">
        <img src="../assets/images/kong.png"
             alt=""
             width="150" />
        <div class="f15 textc mt15 text999">{{ $t("my.noMessage") }}</div>
      </div>

      <van-collapse v-model="activeNames">
        <van-collapse-item :title="item.typeName"
                           :name="i"
                           v-for="(item, i) in machineTypeList"
                           :key="item.id">
          <div class="subNode text111 pb15">
            <div class="item mt10 f14"
                 v-for="itemNode in item.son"
                 :key="itemNode.id">
              <div class="subNode_tit"
                   @click="
                  showTypeData(
                    itemNode.typeCode,
                    itemNode.typeName,
                    itemNode.parentCode
                  )
                ">
                {{ itemNode.typeName }}
              </div>
              <div class="content bbox secNode mt10"
                   v-if="itemNode.son.length > 0">
                <div v-for="itemFinalNode in itemNode.son"
                     :key="itemFinalNode.id"
                     @click="
                    showTypeData(
                      itemFinalNode.typeCode,
                      itemFinalNode.typeName,
                      itemFinalNode.parentCode
                    )
                  ">
                  {{ itemFinalNode.typeName }}
                </div>
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>

      <!-- <div class="parentNode" v-for="item in machineTypeList" :key="item.id">
        <div
          class="tit f15 text111 fw700"
          @click="showMachineNodeDetails(item.typeCode, item.typeName)"
        >
          {{ item.typeName }}
        </div>
        <div class="subNode text111 pb15">
          <div
            class="item mt10 f14"
            v-for="itemNode in item.son"
            :key="itemNode.id"
          >
            <div
              class="subNode_tit"
              @click="
                showTypeData(
                  itemNode.typeCode,
                  itemNode.typeName,
                  itemNode.parentCode
                )
              "
            >
              {{ itemNode.typeName }}
            </div>
            <div
              class="content bbox secNode mt10"
              v-if="itemNode.son.length > 0"
            >
              <div
                v-for="itemFinalNode in itemNode.son"
                :key="itemFinalNode.id"
                @click="
                  showTypeData(
                    itemFinalNode.typeCode,
                    itemFinalNode.typeName,
                    itemFinalNode.parentCode
                  )
                "
              >
                {{ itemFinalNode.typeName }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 弹窗 -->
    <van-dialog v-model="show"
                title="图片展示"
                :show-cancel-button="false"
                :show-confirm-button="false"
                :closeOnClickOverlay="true">
      <div class="content bbox f13 text333 mt10 flex align-center justify-center">
        <!-- <img :src="fileUrls"
             alt=""
             width="100"
             height="100" /> -->
        <van-uploader v-model="fileUrls"
                      :deletable="false"
                      :max-count="1" />
      </div>
      <div class="btns mt15 mb10">
        <div class="textc">
          <el-button type="primary"
                     @click="gotoExamination"
                     class="wp80 f16 Btn">{{ $t("closed") }}</el-button>
        </div>
      </div>
    </van-dialog>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/commonHead.vue";
import Footer from "../components/footer.vue";
import { Dialog, Toast } from "vant";
export default {
  components: { Header, Footer },
  data () {
    return {
      typeName: "",
      typeCode: "",
      nodeCode: "",
      childCode: "",
      machineTypeList: [],
      machineNodeList: [],
      allList: [],
      isAcitive: -1,
      previewAreaTittle: "",
      dialogVisibal: false,
      key: "",
      show: false,
      fileUrls: [{ url: '' }],
      activeNames: ["0"],
    };
  },
  methods: {
    //获取三级及以下的节点
    showMachineNode (typeCode) {
      var sendData = {};
      sendData.typeCode = typeCode;
      sendData.memberCode=JSON.parse(localStorage.getItem("member")).memberCode
      this.machineTypeList.length = 0;
      var that = this;
      this.$http
        .post("machineType/queryMachineTypeListByTypeCode", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            if (that.key != undefined && that.key != "") {
              //显示指定类别
              var treeList = [];
              res.data.data.treeList.forEach((e) => {
                if (e.typeName == that.key) {
                  treeList.push(e);
                  //左侧
                  that.machineTypeList = treeList;
                  //右侧
                  that.machineNodeList = e.son;
                }
              });
            } else {
              that.machineTypeList = res.data.data.treeList;
              that.machineNodeList = res.data.data.allList;
              that.allList = res.data.data.allList;
            }
          }
        });
    },
    //获取子节点文件
    showTypeData (typeCode, typeName, parentCode) {
  
      var isPart = -1;
      this.previewAreaTittle = typeName;
      var sendData = {};
      sendData.objectCode = typeCode;
      for (var i = 0; i < this.allList.length; i++) {
        if (this.allList[i].typeCode == parentCode) {
          isPart = this.allList[i].typeName.indexOf("照片");
          break;
        }
      }
      var that = this;
      this.$http.post("annexFile/queryFileList", sendData).then(function (res) {
        if (200 == res.data.code) {
          var file = res.data.data[0];
          if (file != undefined && file.fileUrl != undefined) {
            //是零部件则展示图片
            if (isPart != -1) {
              that.fileUrls[0].url = "";
              that.fileUrls[0].url = file.fileUrl;
              that.show = true;
              //  that.$router.push({ path: "/picPreview", query: data });
            } else {
              var url = file.fileUrl;
              var content = file.represent;
              that.$router.push({
                path: "/previewFile",
                query: {
                  url: url,
                  content: content,
                  typeCode: typeCode,
                  typeName: typeName,
                  machineType: that.typeName,
                },
              });
             
            }
          } else {
            that.$message("无文件地址,请联系管理员!");
          }
        }
      });
    },
    showMachineNodeDetails (typeCode, typeName) {
      // var isPart = typeName.indexOf("零部件");
      // if (isPart != -1) {
      //   //找到子节点
      //   var that = this;
      //   this.machineTypeList.forEach(e => {
      //     if (e.typeCode == typeCode) {
      //       that.machineNodeList = e.son;
      //       console.log('son:', JSON.stringify(e.son));
      //     }
      //   });
      //   this.gotoPreview(this.machineNodeList);
      // }
    },
    gotoPreview (list) {
      this.$router.push({
        path: "/picPreview",
        query: {
          picList: list,
        },
      });
    },
    gotoExamination () {
      this.show = false;
      this.fileUrls[0].url = "";
    },
  },
  created () {
    this.typeName = this.$route.query.typeName;
    this.typeCode = this.$route.query.typeCode;
    this.key = this.$route.query.key;
    this.showMachineNode(this.typeCode);
  },
};
</script>

<style scoped>
.content {
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 15px;
}
.BoxHeight >>> .van-cell__title {
  font-weight: 700;
  font-size: 15px;
  color: #111;
}
.BoxHeight >>> .van-collapse-item__content {
  padding: 5px 16px;
  color: #111;
}
</style>